import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Header, Icon, Segment, Container, Input, Progress } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';

const Upload = (props) => {

    const navigate = useNavigate();

    const fileInputRef = useRef(null);

    const [file, setFile] = useState(null);

    const [progress, setprogress] = useState(0)

    const [status, setstatus] = useState(0)

    const [rep, setrep] = useState(null)

    const [mdp, setmdp] = useState("")

    useEffect(() => {

        if(props.userinfo.userId === ""){
            navigate(`/fd_admin/login`);
        }

    }, [props])

    const  renameFile = (originalFile, newName) => {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    const handleOnChange = async (e) => {

        let myFile = null;
            
        myFile = renameFile(e.target.files[0],e.target.files[0].name.replaceAll("'"," "));

        setFile(myFile);
        setrep(null);

        console.log(myFile.name);

        const data = new FormData();
        await data.append("file", myFile);
        await data.append('name', myFile.name);

        let request = new XMLHttpRequest();

        if(mdp === ""){
            request.open('POST', `https://data.web-labosims.org/upload.php?pseudo=${props.userinfo.pseudo}&userId=${props.userinfo.userId}`); 
        }else{
            request.open('POST', `https://data.web-labosims.org/upload.php?pseudo=${props.userinfo.pseudo}&userId=${props.userinfo.userId}&mdp=${mdp}`); 
        }
        // upload progress event
        request.upload.addEventListener('progress', function(e) {
            // upload progress as percentage
            let percent_completed = (e.loaded / e.total)*100;
            setprogress(parseInt(percent_completed));
        });

        request.addEventListener('load', function(e) {
            // HTTP status message (200, 404 etc)
            setstatus(request.status);
        
            // request.response holds response from the server
            setrep(JSON.parse(request.response).etat);
        });

        // send POST request to server
        request.send(data);

        /*

        fetch(`https://data.web-labosims.org/upload.php?pseudo=${props.userinfo.pseudo}&userId=${props.userinfo.userId}`, {
            method: 'POST',
            body: data
          }).then((response) => response.json())
          .then((result) => {
              console.log('Success:', result);
          })
          .catch((error) => {
              console.error('Error:', error);
          });

          */
    }

    return ( 
        <Container style={{ marginTop : `${props.height/65}em`}}>
            <Segment placeholder inverted color='green' tertiary style={{padding : "0em"}}>
                <Header icon>
                    <Icon name={"file outline"} />
                    
                </Header>
                <Input 
                    placeholder='Ajouter un mot de passe...'
                    error={false}
                    action={{
                        color: 'black',
                        icon: 'lock',
                        disabled : true
                        }}
                    value={mdp}
                    type="text"
                    style={{margin : "1.5em auto"}}
                    onChange={(e,{value}) => {
                        setmdp(value);
                        }}
                    >
                </Input>
                <input
                    ref={fileInputRef}
                    type="file"
                    hidden
                    onChange={handleOnChange}
                />
                <Button 
                    icon 
                    labelPosition='left' 
                    positive 
                    onClick={() => fileInputRef.current.click()}
                    >
                    <Icon name='upload' />
                    Uploader un fichier
                </Button>
            </Segment>
            <Segment>
            <Header>
                <p style={{fontSize : "0.7em", textAlign : "center"}}>{
                    file !== null ? (
                        `${file.name} - ${file.size >= 1048576 ? `${String(parseFloat(file.size/1048576).toFixed(2)).replace(".",",")} Mo` : `${ file.size >= 1000 ? Math.ceil(file.size/1024) : file.size} Ko`}`
                    ) : (
                        `Aucun fichier à uploader pour l'instant ...`
                    )
                }</p>
            </Header>
            {
                progress !== 0 && progress !== 100 && (
                    <Progress percent={progress} inverted progress style={{ margin : "0.5em 2em"}}>
                    </Progress>
                )
            }
                  
            {
                progress === 100 && (
                    <Progress percent={progress} inverted progress success style={{ margin : "0.5em 2em"}}>
                        {
                            progress === 100 && (
                                <p>Fichier uploadé avec succès</p>
                                )
                            }
                    </Progress>
                )
            }

            {
                rep!== null && status !== 200 && rep.include("errors") && (
                    <Progress percent={100} inverted progress error style={{ margin : "0.5em 2em"}}>
                        {
                            status !== 200 ? (
                                <p>Imposssible joindre le serveur</p>
                            ) : (
                                <p>Erreur : {rep}</p>
                            )
                        }
                    </Progress>
                )
            }
            </Segment>
            
        </Container>
     );
}
 
export default Upload;