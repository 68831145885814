import React,{ useState, useEffect, Fragment} from 'react';
import { Button, Header, Icon, Segment, Container, Input, Message, Select } from 'semantic-ui-react';
import { useParams, useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";

const colors = [
    "red",
    "orange",
    "green",
    "blue",
    "violet",
    "purple"
]
  

const listIcon = [
    {
        name : 'file excel outline',
        extensions : ["xlsx","ods","xls"]
    },
    {
        name : 'file word outline',
        extensions : ["doc","docs"]
    },
    {
        name : 'windows',
        extensions : ["exe","msi"]
    },
    {
        name : 'file video outline',
        extensions : ["avi","mp4","mkv"]
    },
    {
        name : 'file audio outline',
        extensions : ["mp3","m4a"]
    },
    {
        name : 'file archive outline',
        extensions : ["zip","7zip","rar"]
    },
    {
        name : 'file',
        extensions : ["fls","ggb","mol"],
    },
    {
        name : "file image outline",
        extensions : ["jpg","png","gif"]
    }
]

const Fileadmin = (props) => {

    const { id } = useParams();

    const navigate = useNavigate();

    const [icon, seticon] = useState("file outline")

    const [name, setname] = useState("")

    const [url, seturl] = useState("")

    const [see, setsee] = useState("")

    const [date_ajout, setdate_ajout] = useState("")

    const [protect, setprotect] = useState("0")

    const [mdp, setmdp] = useState("")

    const [error, seterror] = useState(false)

    const [load, setload] = useState(false)

    const [deleteOk, setdeleteOk] = useState(false)

    const [updateOk, setupdateOk] = useState(false)

    const [updateOk2, setupdateOk2] = useState(false)

    const [updateOk3, setupdateOk3] = useState(false)

    const [load2, setload2] = useState(false)

    const [error2, seterror2] = useState(false)

    const [load3, setload3] = useState(false)

    const [load4, setload4] = useState(false)

    const [error3, seterror3] = useState(false)

    const [error4, seterror4] = useState(false)

    const [cat, setcat] = useState([])

    const [catOptions, setcatOptions] = useState([])

    const [catSelected, setcatSelected] = useState([])

    const handleClickFilter = async (elem) => {

        if( catSelected.filter(element => element === elem.name ).length === 1 ){      
            setcatSelected( catSelected.filter(element => element !== elem.name ) );
        }else{
            setcatSelected( [...catSelected, elem.name] );
        }

        setupdateOk3(true);

    }

    useEffect(() => {
        fetch("https://data.web-labosims.org/ask.php?mode=4")
        .then((response) => response.json())
        .then((data) => {
            setcat(data);
            let table = [];
            data.forEach( (elem,index) => {
                table.push( {key : index, value: elem.name, text: elem.name} );
            })
            setcatOptions(table);
        })
        .catch((erreur) => console.log(erreur));
    }, [catSelected])

    useEffect(() => {

        setname("");

        let file = [];

        fetch(`https://data.web-labosims.org/ask.php?mode=2&id=${id}`)
        .then((response) => response.json())
        .then((data) => {
            file = data[0];
            console.log(file.categorie);

            if(file.length !== 0){

                let morceaux = file.url.split(".");
                let extension = morceaux[morceaux.length-1];
    
                let name = file.url;
    
                seticon("file outline");
    
                listIcon.forEach(element => {
                    if(element.extensions.includes(extension) === true){
                        seticon(element.name);
                    }
                });

                let date_file = `${file.add_date.substring(8,10)}/${file.add_date.substring(5,7)}/${file.add_date.substring(0,4)}`;

                setname(name);
                seturl(file.id_url);
                setsee(file.see);
                setdate_ajout(date_file);
                setprotect(file.password);

                console.log(file.categorie);

                if(file.categorie === ""){
                    setcatSelected(["Tous les fichiers"]);
                }else{
                    if(Array.isArray(JSON.parse(file.categorie)) === false ){
                        setcatSelected([file.categorie]);
                    }else{
                        setcatSelected( JSON.parse(file.categorie) );
                    }
                }
    
            }else{
                seticon("dont");
                setname("Fichier non trouvé");
                seturl("");
                setdate_ajout("");
                setprotect("0");   
                setsee("1");
                setcatSelected("Tous les fichiers")
            }
        })
        .catch((erreur) => {
            seticon("dont");
            setname("Fichier non trouvé");
            seturl("");
            setdate_ajout("");
            setprotect("0");
        });
        
    }, [id])

    const handleClick = (e) => {

        setload(true);
        seterror(false);

        if(name !== "Fichier non trouvé"){
            setdeleteOk(true);
        }

    }

    const handleClick2 = (e) => {

        setload2(true);
        seterror2(false);

        if(name !== "Fichier non trouvé"){
            setupdateOk(true);
        }

    }

    const handleClick3 = (e) => {

        setload3(true);
        seterror3(false);

        if(name !== "Fichier non trouvé"){
            if(see === "1"){
                setsee("0");
            }else{
                setsee("1");
            }
            setupdateOk2(true);
        }

    }

    useEffect(() => {
      if(deleteOk === true){
            fetch(`https://data.web-labosims.org/delete.php?pseudo=${props.userinfo.pseudo}&userId=${props.userinfo.userId}`, { 
                method: "POST", 
                body: JSON.stringify({ 
                    url : name, 
                    id_url : url
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
            }).then(response => response.json()) 
            .then(data => {
                console.log(data);
                if(data.etat === "ok"){
                    setload(false);
                    navigate(`/fd_admin/home`);
                }else{
                    setload(false);
                    seterror(true);
                }  
            }).catch((error) => {
                console.log("erreur");
                seterror(true);
                setload(false);
            });
            setdeleteOk(false);
      }
    }, [name,url,deleteOk])
    
    useEffect(() => {
        if(updateOk === true){
              fetch(`https://data.web-labosims.org/update.php?pseudo=${props.userinfo.pseudo}&userId=${props.userinfo.userId}`, { 
                  method: "POST", 
                  body: JSON.stringify({ 
                      mdp : mdp, 
                      id_url : url
              }), 
              headers: { 
                  "Content-type": "application/json; charset=UTF-8"
              } 
              }).then(response => response.json()) 
              .then(data => {
                  //console.log(data);
                  if(data.etat === "ok"){
                      setload2(false);
                      navigate(`/fd_admin/home`);
                  }else{
                      setload2(false);
                      seterror2(true);
                  }  
              }).catch((error) => {
                  console.log("erreur");
                  seterror2(true);
                  setload2(false);
              });
              setupdateOk(false);
        }
      }, [mdp,url,updateOk])

      useEffect(() => {
        if(updateOk2 === true){
              fetch(`https://data.web-labosims.org/update2.php?pseudo=${props.userinfo.pseudo}&userId=${props.userinfo.userId}`, { 
                  method: "POST", 
                  body: JSON.stringify({ 
                      see : see, 
                      id_url : url
              }), 
              headers: { 
                  "Content-type": "application/json; charset=UTF-8"
              } 
              }).then(response => response.json()) 
              .then(data => {
                  //console.log(data);
                  if(data.etat === "ok"){
                      setload3(false);
                      navigate(`/fd_admin/home`);
                  }else{
                      setload3(false);
                      seterror3(true);
                  }  
              }).catch((error) => {
                  console.log("erreur");
                  seterror3(true);
                  setload3(false);
              });
              setupdateOk2(false);
        }
      }, [see,url,updateOk2])
    
    const handleKey = async (e) => {
        
        if(e.key === 'Enter'){

            setload2(true);
            seterror2(false);
    
            if(name !== "Fichier non trouvé"){
                setupdateOk(true);
            }
            
        }

    }

    const handleChange = (e, {value}) => {
        setcatSelected(value);
        setupdateOk3(true);
    }

    useEffect(() => {
        if(updateOk3 === true){
              fetch(`https://data.web-labosims.org/update3.php?pseudo=${props.userinfo.pseudo}&userId=${props.userinfo.userId}`, { 
                  method: "POST", 
                  body: JSON.stringify({ 
                      cat : JSON.stringify(catSelected), 
                      id_url : url
              }), 
              headers: { 
                  "Content-type": "application/json; charset=UTF-8"
              } 
              }).then(response => response.json()) 
              .then(data => {
                  //console.log(data);
                  if(data.etat === "ok"){
                      setload4(false);
                      //navigate(`/fd_admin/home`);
                  }else{
                      setload4(false);
                      seterror4(true);
                  }  
              }).catch((error) => {
                  console.log("erreur");
                  seterror4(true);
                  setload4(false);
              });
              setupdateOk3(false);
        }
      }, [catSelected,url,updateOk3])
    
    return ( 
        <Container style={{ paddingTop : `4em`, paddingBottom : "4em"}}>
            <Segment loading={name === ""} placeholder inverted color='blue' tertiary style={{padding : "0em"}}>
                <Header icon>
                <Icon name={icon}  style={{ marginTop : "0.4em" }}/>
                    {name}
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 130, width: "100%", marginTop : "1em" }}>
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`https://web-labosims.org/${id}`}
                        viewBox={`0 0 256 256`}
                        />
                    </div>
                </Header>
                <Button as='a' download href={`/files/${name}`} disabled={icon === "dont"} primary>Télecharger</Button>        
                {
                    date_ajout !== "" && (
                        <p style={{ textAlign : "center", marginTop : "0.9em", marginBottom : "0.6em" , fontWeight : "bold", fontSize : "0.9em"}}>Fichier ajouté le {date_ajout}</p>
                    )
                }
            </Segment>
            <Segment loading={load2} inverted color='green' tertiary>
                <div style={{ display : "flex", flexDirection : "column"}}>
                    <Input
                        action={{
                            color: 'black',
                            icon: 'lock',
                            disabled : true
                        }}
                        placeholder=''
                        value={mdp}
                        onChange={(e,{value}) => setmdp(value)}
                        onKeyDown={handleKey}
                        style={{maxWidth : "17em",margin : "0em auto"}}
                    />
                    <Button type='submit' onClick={handleClick2} positive style={{ maxWidth: "15em", margin : "1em auto 0em auto"}}>Changer le mot de passe</Button>   
                    {
                        error2 === true && (
                            <Message
                                error
                                content='Une erreur est survenue lors de la mise à jour du mot de passe.'
                            />
                        )
                    }
                    {
                        updateOk === true && (
                            <Message
                                positive
                                content='Le mot de passe à été mis à jour.'
                            />
                        )
                    }
                </div>
            </Segment>
            <Segment loading={load3} inverted color='blue' tertiary>
                <div style={{ display : "flex", flexDirection : "column"}}>
                    <Button type='submit' onClick={handleClick3} primary style={{ maxWidth: "15em", margin : "0em auto 0em auto"}}>
                    {
                        see === "1" ? (
                            "Rendre invisible"
                        ) : (
                            "Rendre visible"
                        )
                    }
                    </Button>   
                    {
                        error3 === true && (
                            <Message
                                error
                                content='Une erreur est survenue lors de la mise à jour'
                            />
                        )
                    }
                    {
                        updateOk2 === true && (
                            <Message
                                positive
                                content='Mis à jour ok.'
                            />
                        )
                    }
                </div>
            </Segment>
            <Segment loading={load} inverted color='violet' tertiary>
                <div style={{ display : "flex", flexDirection : "column"}}>
                    <Select placeholder='' options={catOptions} value={catSelected} multiple={true} onChange={handleChange} style={{ maxWidth : 200, margin : "0 auto" }}/>
                    {
                        error4 === true && (
                            <Message
                                error
                                content='Une erreur est survenue lors de la mise à jour de la catégorie.'
                            />
                        )
                    }
                </div>
            </Segment>
            <Segment loading={load} inverted color='red' tertiary>
                <div style={{ display : "flex", flexDirection : "column"}}>
                    <Button type='submit' onClick={handleClick} negative style={{ maxWidth: "15em", margin : "0em auto"}}>Supprimer le fichier</Button>
                    {
                        error === true && (
                            <Message
                                error
                                content='Une erreur est survenue lors de la suppression du fichier.'
                            />
                        )
                    }
                </div>
            </Segment>
        </Container>
     );
}
 
export default Fileadmin;