import React,{ useState, useEffect }  from 'react';
import { useParams } from 'react-router-dom';

function Folder() {

    const { id } = useParams();

    const [name, setname] = useState("")

    useEffect(() => {

        setname("");

        let file = [];

        fetch(`https://api.web-labosims.org/ask/mode/2/id/${id}`)
        .then((response) => response.json())
        .then((data) => {
            file = data[0];

            if(file.length !== 0){

                let name = file.url.replace(".folder","");
    
                setname(name);
    
            }else{
                setname("");
            }
        })
        .catch((erreur) => {      
            setname("");
        });
        
    }, [id])
    
    return (
      <div className="Animation">
        <iframe title="Animation de Physique Chimie" src={`https://data.web-labosims.org/w/${name}/index.html`}></iframe>
      </div>
    );

  }
  
  export default Folder;