import React,{useState, useEffect} from 'react';
import { Container, Table, Menu,Icon,Popup, Button } from 'semantic-ui-react';
import { Link,useNavigate } from 'react-router-dom';

const style = {
    borderRadius: 5,
    opacity: 1,
    padding: '0.5em',
    color : "#FFFFFF",
    backgroundColor : "#000000",
    borderColor : "#000000"
  }

const colors = [
    "red",
    "orange",
    "green",
    "blue",
    "violet",
    "purple"
]
  

const liste = [
    {
        name : "Feuille de calcul Microsoft Excel",
        extensions : ["xlsx","xls"]
    },
    {
        name : "Document Microsoft Word",
        extensions : ["docx","doc"]
    },
    {
        name : "Fichier PDF",
        extensions : ["pdf"]
    },
    {
        name : "Application",
        extensions : ["exe","msi"]
    },
    {
        name : "Fichier vidéo",
        extensions : ["avi","mp4","mkv"]
    },
    {
        name : "Fichier audio",
        extensions : ["mp3","m4a"]
    },
    {
        name : 'Fichier  d\'archive',
        extensions : ["zip","7zip","rar"]
    },
    {
        name : 'Fichier Filius',
        extensions : ["fls"]
    },
    {
        name : 'Fichier GeoGebra',
        extensions : ["ggb"]
    },
    {
        name : "Fichier Image",
        extensions : ["jpg","png","gif"]
    },
    {
        name : 'Fichier ChemSketch',
        extensions : ["mol"]
    },
]

const Home = (props) => {

    const navigate = useNavigate();

    const [page, setpage] = useState(0)

    const [donnees, setdonnees] = useState([])

    const [donneesAff, setdonneesAff] = useState([])

    const [cat, setcat] = useState([])

    const [catSelected, setcatSelected] = useState("Tous les fichiers")

    const handleClick = (elem) => {
        navigate(`/${elem.id_url}`);
      };

    const handleClickPage = (elem) => {
        if(elem >= 0 && elem < Math.ceil(donneesAff.length/5)){
            setpage(elem);
            let modif = [...cat];
            modif.map( element => {
                if(element.name === catSelected){
                    element.page = elem
                }
            });
            setcat(modif);
            console.log(modif);
            localStorage.setItem('paget', JSON.stringify(modif));
        }
    }

    const handleClickFilter = (elem) => {
        console.log(elem.name);
        setcatSelected(elem.name);
        if(elem.name !== "Tous les fichiers"){
            setdonneesAff(donnees.filter( e => JSON.parse(e.categorie).filter(element => element === elem.name ).length === 1 ));
        }else{
            setdonneesAff(donnees);
        }
        localStorage.setItem('pageActive', elem.name);
    }

    useEffect(() => {
        if(localStorage.getItem('paget')){
            let table = JSON.parse(localStorage.getItem('paget'));
            table.forEach( elem => {
                if(catSelected === elem.name){
                    setpage(elem.page);
                }
            });
        }
    }, [catSelected])

    useEffect(() => {
        if( localStorage.getItem('pageActive') ){
            setcatSelected(localStorage.getItem('pageActive'));

            if(localStorage.getItem('pageActive') !== "Tous les fichiers"){
                setdonneesAff(donnees.filter( e => JSON.parse(e.categorie).filter(elem => elem === localStorage.getItem('pageActive') ).length === 1 ));
            }else{
                setdonneesAff(donnees);
            }
        }
    }, [donnees])

    useEffect(() => {
        fetch("https://data.web-labosims.org/ask.php?mode=3")
        .then((response) => response.json())
        .then((data) => {
            setdonnees(data);
            setdonneesAff(data);
        })
        .catch((erreur) => console.log(erreur));
    }, [])

    useEffect(() => {
        fetch("https://data.web-labosims.org/ask.php?mode=4")
        .then((response) => response.json())
        .then((data) => {
            setcat(data);
            let lecats = [];
            if(localStorage.getItem('paget')){
                let lespage = JSON.parse(localStorage.getItem('paget'));
                console.log(lespage);
                data.forEach( (elem,index) => {
                    let val = lespage.filter( e => e.name === elem.name )[0].page;
                    console.log(val);
                    lecats.push({ id : elem.id, name : elem.name , page : val });
                });
            }else{
                data.forEach( elem => {
                    lecats.push({ id : elem.id, name : elem.name , page : 0 });
                });
            }
            setcat(lecats);
        })
        .catch((erreur) => console.log(erreur));
    }, [])
    

    return ( 
        <div>
            <Container style={{ paddingTop : `${props.height/48}em`, paddingBottom : "4em"}}>
                <div style={{  display : 'flex', flexWrap : 'wrap', alignContent : 'flex-start', marginBottom : "0px"}}>
                    {
                        cat.map( (elem,index) => {
                            if( elem.name === catSelected ){
                                return (
                                    <Button key={`bouton${index}`} color={colors[index]} inverted={false} onClick={() => handleClickFilter(elem)} style={{ marginBottom : "0.4em" }}>
                                        {elem.name}
                                    </Button>
                                )
                            }else{
                                return (
                                    <Button key={`bouton${index}`} color={colors[index]} inverted={true} onClick={() => handleClickFilter(elem)} style={{ marginBottom : "0.4em" }}>
                                        {elem.name}
                                    </Button>
                                )
                            }
                        })
                    }
                </div>
                <Table color="blue" inverted selectable singleLine unstackable style={{ marginTop : "0.1em" }}>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Nom du fichier</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Date d'ajout</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center"><Icon name='lock' />/<Icon name='unlock' /></Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body style={{ cursor : "pointer"}}>

                    {
                        donneesAff.length !== 0 ? donneesAff.map( (elem,index) => {

                            
                            if( (index >= (page*5) && index < ((page+1)*5)) || donneesAff.length < 5 ){
                                
                                let name = elem.url;

                                let morceaux2 = elem.url.split(".");
                                let extension = morceaux2[morceaux2.length-1];

                                let nameType = "";

                                let date_file = "";

                                const timestamp = elem.add_date;

                                date_file = `${timestamp.substring(8,10)}/${timestamp.substring(5,7)}/${timestamp.substring(0,4)}`;

                                liste.forEach(element => {
                                    if(element.extensions.includes(extension) === true){
                                        nameType = element.name;
                                    }
                                });
                                if(props.width < 550){
                                    let nameType2 = nameType.substring(0,8)+ "...";
                                    let name2 = name.substring(0,12) + "...";
                                    return (
                                        <Table.Row key={elem.id_url} onClick={() => handleClick(elem)}>
                                            <Popup
                                                trigger={<Table.Cell>{name2}</Table.Cell>}
                                                content={name}
                                                position='top center'
                                                style={style}
                                            />
                                            <Popup
                                                trigger={<Table.Cell>{nameType2}</Table.Cell>}
                                                content={nameType}
                                                position='top center'
                                                style={style}
                                            />
                                            <Table.Cell>{date_file}</Table.Cell>
                                            {
                                                elem.password === "0" ? (
                                                    <Table.Cell textAlign="center"><Icon name='unlock' /></Table.Cell>
                                                ) : (
                                                    <Table.Cell textAlign="center"><Icon name='lock' /></Table.Cell>
                                                )
                                            }
                                        </Table.Row>
                                    )
                                }else if(name.length > 28){
                                    
                                    let name2 = name.substring(0,28) + "...";
                                    return (
                                        <Table.Row key={elem.id_url} onClick={() => handleClick(elem)}>
                                            <Popup
                                                trigger={<Table.Cell>{name2}</Table.Cell>}
                                                content={name}
                                                position='top center'
                                                style={style}
                                            />
                                            <Table.Cell>{nameType}</Table.Cell>
                                            <Table.Cell>{date_file}</Table.Cell>
                                            {
                                                elem.password === "0" ? (
                                                    <Table.Cell textAlign="center"><Icon name='unlock' /></Table.Cell>
                                                ) : (
                                                    <Table.Cell textAlign="center"><Icon name='lock' /></Table.Cell>
                                                )
                                            }
                                        </Table.Row>
                                    )
                                }else{
                                    return (
                                        <Table.Row key={elem.id_url} onClick={() => handleClick(elem)}>
                                            <Table.Cell>{name}</Table.Cell>
                                            <Table.Cell>{nameType}</Table.Cell>
                                            <Table.Cell>{date_file}</Table.Cell>
                                            {
                                                elem.password === "0" ? (
                                                    <Table.Cell textAlign="center"><Icon name='unlock' /></Table.Cell>
                                                ) : (
                                                    <Table.Cell textAlign="center"><Icon name='lock' /></Table.Cell>
                                                )
                                            }
                                        </Table.Row>
                                    )
                                }
                            }
                        }) : (
                            <Table.Row >
                                <Table.Cell>Aucun fichier</Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                            </Table.Row>
                        )
                    }
                    </Table.Body>
                    {
                        donneesAff.length > 5 && (
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='4'>
                                    <Menu inverted color='blue' size='tiny' floated='right' pagination>
                                        <Menu.Item as='a' icon onClick={() => handleClickPage(page-1)}>
                                            <Icon name='chevron left'/>
                                        </Menu.Item>
                                        {
                                            [...Array(Math.ceil(donneesAff.length/5)).keys()].map( (elem,index) => <Menu.Item key={index} active={elem === page} onClick={() => handleClickPage(elem)} as='a'>{elem+1}</Menu.Item>)
                                        }
                                        <Menu.Item as='a' icon onClick={() => handleClickPage(page+1)}>
                                            <Icon name='chevron right' />
                                        </Menu.Item>
                                    </Menu>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        )
                    }
                </Table>
            </Container>
        </div>
     );
}
 
export default Home;