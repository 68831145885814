import React,{useState} from 'react';
import { Menu, Segment, Icon, Button } from 'semantic-ui-react';
import { Link,useLocation, useNavigate } from 'react-router-dom';

const Header = (props) => {

    let activeItem = useLocation();

    const navigate = useNavigate();

    const [activeI, setactiveI] = useState(false)

    const onClick = (e) => {
        setactiveI(true);
        props.setuserinfo("","");
        navigate("/");
    }

    return ( 
        <header>
          <Menu icon inverted fixed='top' size='tiny'>
              <Menu.Item
                  name='accueil' 
                  color={"blue"}
                  as={Link}
                  active={activeItem.pathname === '/' || activeItem.pathname === '/fd_admin/home'} 
                  to={ props.userinfo.userId === "" ? "/" : "/fd_admin/home" }
                  >
                  <Icon name='home' />
              </Menu.Item>
              {
                  props.userinfo.userId !== "" && (
                    <Menu.Item 
                        position='right'
                        color={"red"}
                        active={activeI}
                        onClick={onClick}
                    >
                        <Icon name='log out' />
                    </Menu.Item>
                  )
              }
            </Menu>
        </header>
    );

}
 
export default Header;