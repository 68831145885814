import React,{ useState, useEffect, Fragment} from 'react';
import { Button, Header, Icon, Segment, Container, Input } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import QRCode from "react-qr-code";

const listIcon = [
    {
        name : 'file excel outline',
        extensions : ["xlsx","ods","xls"]
    },
    {
        name : 'file word outline',
        extensions : ["doc","docx"]
    },
    {
        name : 'file pdf outline',
        extensions : ["pdf"]
    },
    {
        name : 'windows',
        extensions : ["exe","msi"]
    },
    {
        name : 'file video outline',
        extensions : ["avi","mp4","mkv"]
    },
    {
        name : 'file audio outline',
        extensions : ["mp3","m4a"]
    },
    {
        name : 'file archive outline',
        extensions : ["zip","7zip","rar"]
    },
    {
        name : 'file',
        extensions : ["fls","ggb","mol"]
    },
    {
        name : "file image outline",
        extensions : ["jpg","png","gif"]
    }
]

const File = (props) => {

    const { id } = useParams();

    const [icon, seticon] = useState("file outline")

    const [name, setname] = useState("")

    const [url, seturl] = useState("")

    const [date_ajout, setdate_ajout] = useState("")

    const [protect, setprotect] = useState("0")

    const [mdp, setmdp] = useState("")

    const [error, seterror] = useState(false)

    useEffect(() => {

        setname("");

        let file = [];

        fetch(`https://data.web-labosims.org/ask.php?mode=2&id=${id}`)
        .then((response) => response.json())
        .then((data) => {
            file = data[0];

            if(file.length !== 0){

                let morceaux = file.url.split(".");
                let extension = morceaux[morceaux.length-1];
    
                let name = file.url;
    
                seticon("file outline");
    
                listIcon.forEach(element => {
                    if(element.extensions.includes(extension) === true){
                        seticon(element.name);
                    }
                });

                let date_file = `${file.add_date.substring(8,10)}/${file.add_date.substring(5,7)}/${file.add_date.substring(0,4)}`;

                setname(name);
                seturl(file.id_url);
                setdate_ajout(date_file);
                setprotect(file.password);
    
            }else{
                seticon("dont");
                setname("Fichier non trouvé");
                seturl("");
                setdate_ajout("");
                setprotect("0");   
            }
        })
        .catch((erreur) => {
            seticon("dont");
            setname("Fichier non trouvé");
            seturl("");
            setdate_ajout("");
            setprotect("0");
        });
        
    }, [id])

    const handleClick = (e) => {

        setprotect("1");

        fetch("https://data.web-labosims.org/connect.php", { 
                method: "POST", 
                body: JSON.stringify({ 
                    mdp : mdp, 
                    id_url : url
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
            }).then(response => response.json()) 
            .then(data => {
                console.log(data);
                if(data["valide"] === true){
                    setprotect("0");
                    seterror(false);
                }else{
                    seterror(true);
                }
            }).catch((error) => {
                console.log("erreur");
            })

    }

    const handleKey = (e) => {
        if(e.key === 'Enter'){

            setprotect("1");

            fetch("https://data.web-labosims.org/connect.php", { 
                method: "POST", 
                body: JSON.stringify({ 
                    mdp : mdp, 
                    id_url : url
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
            }).then(response => response.json()) 
            .then(data => {
                console.log(data);
                if(data["valide"] === true){
                    setprotect("0");
                    seterror(false);
                }else{
                    seterror(true);
                }
            }).catch((error) => {
                console.log("erreur");
            })
        }
    }
    
    
    return ( 
        <Container style={{ paddingTop : `${props.height/48}em`, paddingBottom : "4em"}}>
            <Segment loading={name === ""} placeholder inverted color='blue' tertiary style={{padding : "0em"}}>
                <Header icon>
                <Icon name={icon} style={{ marginTop : "0.4em" }}/>
                    {name}
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 130, width: "100%", marginTop : "1em" }}>
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`https://web-labosims.org/${id}`}
                        viewBox={`0 0 256 256`}
                        />
                    </div>
                </Header>
                {
                    protect === "0" ? (
                        <Button as='a' download href={`/files/${name}`} disabled={icon === "dont"} primary>Télecharger</Button>
                    ) : (
                        <Input 
                            placeholder='Mot de passe...'
                            error={error === true}
                            action={{
                                color: 'blue',
                                icon: 'unlock',
                                onClick: handleClick
                                }}
                            value={mdp}
                            type="password"
                            onChange={(e,{value}) => {
                                setmdp(value);
                                seterror(false);
                                }}
                            onKeyDown={handleKey}
                        >
                        </Input>
                    )
                }              
                {
                    date_ajout !== "" && (
                        <p style={{ textAlign : "center", marginTop : "0.9em", marginBottom : "0.6em" , fontWeight : "bold", fontSize : "0.9em"}}>Fichier ajouté le {date_ajout}</p>
                    )
                }
            </Segment>
        </Container>
     );
}
 
export default File;