import React,{ useState, useEffect, Fragment, useRef} from 'react';
import { Button, Header, Icon, Segment, Container, Input, Form } from 'semantic-ui-react';
import { useParams,useNavigate } from 'react-router-dom';

const Login = (props) => {

    const navigate = useNavigate();

    const [error1, seterror1] = useState(false)

    const [error2, seterror2] = useState(false)

    const [pseudo, setpseudo] = useState("")

    const [mdp, setmdp] = useState("")

    const [pseudosend, setpseudosend] = useState("")

    const [mdpsend, setmdpsend] = useState("")

    const [userId, setuserId] = useState("")

    const [load, setload] = useState(false)

    const [nb, setnb] = useState(0)

    const [timer, setTimer] = useState(120); // 2 minutes

    const [start, setStart] = useState(false)

    const tick = useRef()

    useEffect(() => {
    
        if (start) {
            setTimer(120);
          tick.current = setInterval(() => { 
            if( timer > 0){
                setTimer((timer) => timer - 1);
            }
          }, 1000);
        }else{
          clearInterval(tick.current); // <-- access tick ref current value
        }
    
        return () => clearInterval(tick.current);
      }, [start]);

      useEffect(() => {

            if(timer <= 0) {
                setStart(false);
                clearInterval(tick.current);
                setnb(0);
            }

      }, [timer]);

    useEffect(() => {
      if(props.userinfo.userId !== ""){
            navigate("/fd_admin/home");
      }
    }, [props])

    useEffect(() => {
        setnb(0);
      }, [])

    const handlePseudo = (e,{value}) => {
        setpseudo(value);
    }

    const handleMdp = (e,{value}) => {
        setmdp(value);
    }

    const handleSubmit = (e) => {
        
        if(pseudosend !== pseudo && mdp !== mdpsend){
            setpseudosend(pseudo);
            setmdpsend(mdp);

            setload(true);

            fetch("https://data.web-labosims.org/login.php", { 
                method: "POST", 
                body: JSON.stringify({ 
                    pseudo : pseudo, 
                    password : mdp
            }), 
            headers: { 
                "Content-type": "application/json; charset=UTF-8"
            } 
            }).then(response => response.json()) 
            .then(data => {
                if(data.userId !== ""){
                    setload(false);
                    setuserId(data.userId);
                    props.setuserinfo(pseudo,data.userId);
                    navigate(`/fd_admin/home`);
                }else{
                    setmdp("");
                    setpseudosend("");
                    setmdpsend("");
                    setload(false);
                    seterror1(true);
                    seterror2(true);
                    setnb(nb+1);
                    if(nb+1 >= 3){
                        setStart(true);
                    }
                }  
            }).catch((error) => {
                console.log("erreur");
                seterror1(true);
                seterror2(true);
                setload(false);
            })
        }

    }

    return ( 
        <Container style={{ marginTop : `${props.height/55}em`}}>
            <Segment placeholder inverted color='blue' tertiary style={{maxWidth : "28em",margin : "0 auto"}}>
                <Header icon>
                    <Icon name={"sign in"} />
                </Header>
                <Form>
                    <Form.Field>
                        <label>Pseudo</label>
                        <Input placeholder='Pseudo...' loading={load === true} value={pseudo} error={error1 === true} disabled={nb >= 3} onChange={(e,{value}) => handlePseudo(e,{value})} />
                    </Form.Field>
                    <Form.Field>
                        <label>Mot de passe</label>
                        <Input type="password" loading={load === true} placeholder='' value={mdp} error={error2 === true} disabled={nb >= 3} onChange={(e,{value}) => handleMdp(e,{value})} />
                    </Form.Field>
                    <Form.Field>
                    </Form.Field>
                    <Button primary type='submit' onClick={handleSubmit} style={{ margin : "2em auto 1em auto"}}>Se connecter</Button>
                    {
                        ( nb > 0 && nb < 3 ) && (
                            <p style={{ textAlign : "center" , color : "purple", fontWeight : "bold", fontSize : "1.3em"}}>Vous avez encore {3-nb} essais, après cela vous devrez attendre un peu...</p>
                        )
                    }
                    {
                        nb >= 3 && (
                            <p style={{ textAlign : "center" , color : "purple", fontWeight : "bold", fontSize : "1.3em"}}>Vous devez attendre {Math.floor(timer / 60)} minutes {timer % 60} secondes avant de pouvour essayer à nouveau de vous connecter</p>
                        )
                    }
                </Form>
            </Segment>
        </Container>
     );
}
 
export default Login;