import { Component, Fragment,useState, useEffect, useRef } from 'react';
import Footer from './components/Footer';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Home from './components/Home';
import File from './components/File';
import Header from './components/Header';
import Upload from './components/Upload';
import Login from './components/Login';
import Admin from './components/Admin';
import Fileadmin from './components/Fileadmin';
import Folder from './components/Folder';

const version = "1.6.2 du 05/04/2023";

class App extends Component {
  constructor(props) {
    super(props);
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.setuserinfo = this.setuserinfo.bind(this);
  }
  state = { 
      height : window.innerHeight,
      width : window.innerWidth,
      userInfo : {
          pseudo : "",
          userId : ""
      }
   }

  componentDidMount(){
      window.addEventListener('resize', this.handleWindowSizeChange);

      if(localStorage.getItem('pseudo') && localStorage.getItem('userId')){
          this.setState({
              userInfo : {
                  pseudo : localStorage.getItem('pseudo'),
                  userId : localStorage.getItem('userId')
              }
          });
      }
  }

  componentWillUnmount(){
      window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
      this.setState({
          height : window.innerHeight,
          width : window.innerWidth
      })
  }

  setuserinfo(pseudo,id){
      this.setState({
          userInfo : {
              pseudo : pseudo,
              userId : id
          }
      });
      localStorage.setItem('pseudo', pseudo);
      localStorage.setItem('userId', id);
  }

  render() { 
    return ( 
      <Fragment>
          <Router>   
            <Header userinfo={this.state.userInfo} setuserinfo={this.setuserinfo}/>      
            <Routes>
              <Route exact path="/" element={<Home height={this.state.height} width={this.state.width}/>}/>
              <Route path="/:id" element={<File height={this.state.height}/>}/>
              <Route path="/files/*" element={<Navigate to="/" replace />}/>
              <Route path="/fd_admin/upload" element={<Upload height={this.state.height} userinfo={this.state.userInfo}/>}/>
              <Route path="/fd_admin/login" element={<Login userinfo={this.state.userInfo} setuserinfo={this.setuserinfo} height={this.state.height}/>}/>
              <Route path="/fd_admin/home" element={<Admin userinfo={this.state.userInfo} height={this.state.height} width={this.state.width}/>}/>
              <Route path="/fd_admin" element={<Navigate to="/fd_admin/login" replace />}/>
              <Route path="/fd_admin/:id" element={<Fileadmin userinfo={this.state.userInfo} height={this.state.height}/>}/>
              <Route path="/w/:id" element={<Folder userinfo={this.state.userInfo} height={this.state.height}/>}/>
            </Routes>
          </Router>
          <Footer version={version}/>
      </Fragment> 
      );
  }
}
 
export default App;
