import React from 'react';

const Footer = ({version}) => {
    return ( 
        <footer>
          <p style={{ fontSize : "0.9em" }}>Réalisé par S. JACQUINET &nbsp;&nbsp;version {version}</p>
        </footer>
     );
}
 
export default Footer;